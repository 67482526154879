import React from "react"
import { RichText } from "prismic-reactjs"
import Img from 'gatsby-image'
import SEO from "../components/seo"
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import Transitions from '../components/transitions'
import GreenDot from '../components/green-dot'

const About = ({ content }) => {
  return (
    <>
      <SEO
        image={content.group_photo.url+"&w=1200"}
        title={RichText.asText(content.seo_title)}
        keywords={RichText.asText(content.seo_keywords)}
        description={RichText.asText(content.seo_description)}
        url="https://bonsai.is/about"
      />
      <div className="full_banner d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-11">
              <Transitions.SlideUp>
                <h1 className="about-heading">{RichText.asText(content.title)}<GreenDot/></h1>
              </Transitions.SlideUp>
            </div>
          </div>
        </div>
      </div>

      <div className="information_area">
        <div className="container">
          <div className="information_inner">
            <div className="information_text mb-0">
              <Transitions.SlideUp>
                <p>{RichText.asText(content.header_text)}</p>
              </Transitions.SlideUp>
            </div>
          </div>
        </div>
      </div>

      <Img className="about_full_image" s fluid={content.group_photoSharp.childImageSharp.fluid} alt={content.group_photo.alt}/>

      <div className="team_area">
        <div className="container">
          {content.body.map((body) => {
            return(
              <>
                <div className="row">
                  <div className="col-6 d-flex flex-column">
                    <h2>{RichText.asText(body.primary.name)}</h2>
                    <h3>{RichText.asText(body.primary.position)}</h3>
                    
                    
                    <p className="d-none d-md-block">{RichText.asText(body.primary.about)}</p>

                    <div className="team_social mt-auto d-none d-md-block">
                      <a href={body.primary.linkedin.url} add target="_blank" rel="noreferrer">
                        <img src="../../linkedinIcon.svg" alt="Linkedin Icon" class="headerLogo"/>
                      </a>
                      <h4>
                        <a style={{textDecoration: 'none'}} href={`tel:${RichText.asText(body.primary.phone)}`}>{RichText.asText(body.primary.phone)}</a>
                      </h4>
                      <h4>{RichText.asText(body.primary.email)}</h4>
                    </div>

                  </div>
                  <div className="col-6">
                    <Img fluid={body.primary.imageSharp.childImageSharp.fluid} alt={body.primary.image.alt}/>
                  </div>

                  <div className="col-12 mt-3 d-block d-md-none">
                    <p>{RichText.asText(body.primary.about)}</p>

                    <div className="team_social text-right">
                      <a href={body.primary.linkedin.url} add target="_blank" rel="noreferrer">
                        <img src="../../linkedinIcon.svg" alt="Linkedin Icon" class="headerLogo"/>
                      </a>
                      <h4 className="mb-0"><a style={{textDecoration: 'none'}} href={`tel:${RichText.asText(body.primary.phone)}`}>{RichText.asText(body.primary.phone)}</a></h4>
                      <h4>{RichText.asText(body.primary.email)}</h4>
                    </div>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default ({ data }) => {
  const content = data.prismic.allAbouts.edges[0].node
  if (!content) return null
  return (
    <Layout>
      <About content={content}/>
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      allAbouts {
        edges {
          node {
            title
            seo_title
            seo_keywords
            seo_description
            header_text
            group_photo
            group_photoSharp {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            body {
              ... on PRISMIC_AboutBodyStaff {
                primary {
                  about
                  email
                  image
                  linkedin {
                    ... on PRISMIC__ExternalLink {
                      target
                      _linkType
                      url
                    }
                  }
                  imageSharp {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  name
                  phone
                  position
                }
              }
            }
          }
        }
      }
    }
  }
`